<!--常见问题-->
<template>
    <div class="container problem-box">
         <div class="row">
            <div class="col-md-12">
                <h1 class="text-capitalize">常见问题</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="topics-box">
                    <!--<h3>话题</h3>
                    <ul class="above-box">
                      <li v-for="item in topicsList" :key="item.id">
                         <a href="">发现与固废数据库有关的差异或问题时，应该联系谁？</a>
                      </li>
                    </ul>-->
                    <hr>
                    <ul class="below-box">
                         <li v-for="item in problemList" :key="item.id">
                           <p class="ask">{{item.ask}}</p>
                           <p class="answer">{{item.answer}}</p>
                         </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'faq',
  data(){
      return{
        problemList:[
          {
            ask:"发现与固废数据库有关的差异或问题时，应该联系谁？",
            answer:"可给我们留言，与我们联系。",
            id:1
          }
        ],
        topicsList:[
          {
            name:"发现与固废数据库有关的差异或问题时，应该联系谁？",
            id:1
          },
          {
            name:"发现与固废数据库有关的差异或问题时，应该联系谁？",
            id:2
          },
          {
            name:"发现与固废数据库有关的差异或问题时，应该联系谁？",
            id:3
          }
        ]
      }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/problem.less";  
</style>